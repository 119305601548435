// In src/App.js
import React, { useState, useEffect } from 'react';

const easeInOutCubic = (x) => {
  return x < 0.5 
    ? 4 * x * x * x 
    : 1 - Math.pow(-2 * x + 2, 3) / 2;
};

const AnimatedNumber = ({ value, duration = 1500 }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);
  
  useEffect(() => {
    if (value !== prevValue) {
      const start = prevValue;
      const end = value;
      const startTime = Date.now();
      
      const animate = () => {
        const now = Date.now();
        const elapsed = now - startTime;
        const rawProgress = Math.min(elapsed / duration, 1);
        
        const easedProgress = easeInOutCubic(rawProgress);
        const current = Math.round(start + (end - start) * easedProgress);
        setDisplayValue(current);
        
        if (rawProgress < 1) {
          requestAnimationFrame(animate);
        }
      };
      
      requestAnimationFrame(animate);
      setPrevValue(value);
    }
  }, [value, duration, prevValue]);
  
  return displayValue;
};

function App() {
  const [numbers, setNumbers] = useState([4, 3, 2]);
  const [letters, setLetters] = useState(['W', 'A', 'B']);
  const [positions, setPositions] = useState([0, 1, 2]);
  
  const availableLetters = ['W', 'P', 'K', 'M', 'D', 'A'];
  
  const generateNumber = () => {
    const newNumbers = numbers.map(() => Math.floor(Math.random() * 30) + 1);
    const newLetters = letters.map(() => availableLetters[Math.floor(Math.random() * availableLetters.length)]);
    setNumbers(newNumbers);
    setLetters(newLetters);
  };

  const shufflePositions = () => {
    const newPositions = [...positions];
    for (let i = newPositions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newPositions[i], newPositions[j]] = [newPositions[j], newPositions[i]];
    }
    setPositions(newPositions);
  };
  
  const renderPair = (position, index) => (
    <>
      {letters[position]}<AnimatedNumber value={numbers[position]} />
      {index < 2 && '.'}
    </>
  );

  return (
    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
      <div style={{ fontSize: '1.5rem', fontFamily: 'monospace' }}>
        {positions.map((pos, idx) => renderPair(pos, idx))}
      </div>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <button 
          onClick={generateNumber}
          style={{ 
            padding: '0.5rem 1rem',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Neue Nummer generieren
        </button>
        <button 
          onClick={shufflePositions}
          style={{ 
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            border: '1px solid #007bff',
            color: '#007bff',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          🔀 Neu sortieren
        </button>
      </div>
    </div>
  );
}

export default App;